import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

const Dots = ({
  dots,
  activeSlide,
  handleClick,
  wrapperProps,
  ...dotProps
}) => {
  const { className, activeClassName, ...otherDotProps } = dotProps;

  const onDotClick = (e, idx) => {
    e.preventDefault();
    handleClick && handleClick(idx);
  };

  return (
    <div className={`${styles["slider-dots"]}`} {...wrapperProps}>
      {dots.map((dot, idx) => (
        <span
          key={`slide-dot-${idx}`}
          className={`${styles["slider-dot"]} ${className || ""} ${
            activeSlide === idx
              ? activeClassName || styles["slider-dot-active"]
              : ""
          }`}
          onClick={(e) => onDotClick(e, idx)}
          {...otherDotProps}
        ></span>
      ))}
    </div>
  );
};

Dots.propTypes = {
  wrapperProps: PropTypes.object,
  dotProps: PropTypes.object,
  slides: PropTypes.array,
  activeSlide: PropTypes.number,
  handleClick: PropTypes.func,
};

export default Dots;
