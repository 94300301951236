import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import useResizeScreenModified from "common/hooks/useResizeScreenModified";

import styles from "./index.module.scss";

const SliderContent = ({
  translate,
  transition,
  effect,
  width,
  children,
  nextCallback,
  prevCallback,
  dragable,
  vertical,
  ...wrapperProps
}) => {
  const [styleEffect, setStyleEffect] = useState(null);
  const [element, setElement] = useState(null);
  const [axis, setAxis] = useState("X");
  const [screen, setScreen] = useState(null);
  useResizeScreenModified(setScreen);

  let pressed = false;
  let startX;
  let scrollLeft;

  useEffect(() => {
    if (!element) {
      return;
    }

    let onMouseEnter,
      onMouseDown,
      onMouseMove,
      onMouseUp,
      onMouseLeave,
      onTouchStart,
      onTouchMove,
      onTouchEnd;

    if (element && effect === "scroll" && dragable) {
      if (screen === "DESKTOP") {
        onMouseEnter = element.addEventListener("mouseenter", enter);
        onMouseDown = element.addEventListener("mousedown", start);
        onMouseMove = element.addEventListener("mousemove", move);
        onMouseUp = element.addEventListener("mouseup", change);
        onMouseLeave = element.addEventListener("mouseleave", end);
      } else {
        onTouchStart = element.addEventListener("touchstart", start, {
          capture: true,
          passive: true,
        });
        onTouchMove = element.addEventListener("touchmove", move);
        onTouchEnd = element.addEventListener("touchend", change);
      }
    }

    return () => {
      element.removeEventListener("mouseenter", onMouseEnter);

      element.removeEventListener("mousedown", onMouseDown);
      element.removeEventListener("touchstart", onTouchStart);

      element.removeEventListener("mousemove", onMouseMove);
      element.removeEventListener("touchmove", onTouchMove);

      element.removeEventListener("mouseup", onMouseUp);

      element.removeEventListener("mouseleave", onMouseLeave);
      element.removeEventListener("touchend", onTouchEnd);
    };
  }, [screen, element, effect, dragable, translate]);

  useEffect(() => {
    if (effect) {
      switch (effect) {
        case "scroll":
          setStyleEffect({
            transform: `translate${axis}(-${translate}px)`,
            transition: `transform ease-in-out ${transition}s`,
            width: `${width}px`,
          });
          break;
        case "flip":
          setStyleEffect({ width: `${width}px` });
          break;
        case "fade":
          setStyleEffect({ width: `${width}px` });
          break;
        default:
          setStyleEffect({
            transform: `translate${axis}(-${translate}px)`,
            transition: `transform ease-in-out ${transition}s`,
            width: `${width}px`,
          });
          break;
      }
    }
  }, [effect, translate, transition, width, axis]);

  useEffect(() => {
    if (vertical) {
      setAxis("Y");
    } else {
      setAxis("X");
    }
  }, [vertical]);

  const change = (e) => {
    end();

    if (scrollLeft) {
      if (translate - scrollLeft > translate && -scrollLeft > 300) {
        // nextSlide();
        nextCallback && nextCallback();
      } else if (translate - scrollLeft < translate && scrollLeft > 300) {
        // prevSlide();
        prevCallback && prevCallback();
      }
    }
  };

  const end = () => {
    pressed = false;
    element.style.cursor = "grab";
    // element.style.transform = `translateX(-${translate}px)`;
    // element.style.transition = `transform ease-in-out ${transition}s`;

    setStyleEffect({
      transform: `translateX(-${translate}px)`,
      transition: `transform ease-in-out ${transition}s`,
      width: `${width}px`,
    });
  };

  const start = (e) => {
    pressed = true;
    startX = e.pageX || e.touches[0].pageX - translate;
    element.style.cursor = "grabbing";
  };

  const enter = () => {
    element.style.cursor = "grab";
  };

  const move = (e) => {
    if (!pressed) return;

    e.preventDefault();
    const x = e.pageX || e.touches[0].pageX - translate;
    const dist = x - startX;
    scrollLeft = dist;
    // element.style.transform = `translateX(-${translate - dist}px)`;
    // element.style.transition = `none`;

    setStyleEffect({
      transform: `translateX(-${translate - dist}px)`,
      transition: `transform ease-in-out 0s`,
      width: `${width}px`,
    });
  };

  return (
    <div
      ref={(el) => {
        if (!el) return;
        setElement(el);
      }}
      className={`${
        vertical ? styles["slider-content-vertical"] : styles["slider-content"]
      }`}
      style={styleEffect}
      data-effect={effect}
      {...wrapperProps}
    >
      {children}
    </div>
  );
};

SliderContent.propTypes = {
  translate: PropTypes.number,
  transition: PropTypes.number,
  width: PropTypes.number,
  children: PropTypes.node,
  wrapperProps: PropTypes.object,
  effect: PropTypes.string,
  dragable: PropTypes.bool,
};

export default SliderContent;
