/*** Vendors ***/
import Img from "components/atoms/Img";
import ContactForm from "./ContactForm/index";
import { MdLocationPin, MdMail, MdLocalPhone } from "react-icons/md";
import LazyLoad from "react-lazyload";
import Extracurricular from "./Extracurricular";
import { RiWhatsappFill } from "react-icons/ri";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/common/ErrorFallback";

/*** components ***/
import Map from "./Map";
import CountUp from "./CountUp";
import Stories from "./Stories";
import Buckets from "./Buckets";
import Banner from "./Banner";

const ItemCard = ({ data, children, subject, ...props }) => {
  const { className, titleProps, descriptionProps, ...otherProps } = props;
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div
        className={`flex h-full flex-col items-center justify-start rounded-xl bg-white p-10 ${
          className || ""
        }`}
        {...otherProps}
      >
        <div className="mb-6">
          <Img
            src={data?.image?.src}
            width={data?.image?.width}
            height={data?.image?.height}
            className="h-full w-full"
            wrapper={{
              className: "object-square",
              style: {
                width: `${data?.image?.width}px`,
                height: `${data?.image?.height}px`,
              },
            }}
          />
        </div>
        <div className="flex w-full flex-col items-center justify-center font-Montserrat">
          <h4 className="mb-4 text-xl font-bold" {...(titleProps || null)}>
            {data?.title}
          </h4>
          <div
            dangerouslySetInnerHTML={{ __html: data?.description }}
            className="text-base text-arrivalhub-dark-blue-400"
            {...(descriptionProps || null)}
          />
        </div>
      </div>
      {children}
    </ErrorBoundary>
  );
};

/** ========== */
const Home = ({ staticData }) => {
  return (
    <>
      <section className="mb-10 h-screen overflow-hidden lg:mb-20">
        <Banner background={staticData.banner.background} />
      </section>
      <section className="relative mb-6 overflow-hidden lg:mb-8">
        <div className="absolute top-0 left-0 right-0 z-10 m-auto ml-auto mr-auto h-full text-center xl:w-[1336px]">
          <Img
            src={"/assets/images/home/map-why-choosing-arrivalHub.png"}
            loading="eager"
            alt="banner"
            className="hidden h-full w-full object-cover opacity-30 lg:block"
          />
        </div>
        <div className="container relative z-40 text-center">
          <h2 className="mb-6 text-center font-Montserrat text-3xl font-bold leading-10 text-black lg:text-arrivalhub-title">
            Why choosing{" "}
            <span className="text-arrivalhub-main">ArrivalHub?</span>
          </h2>
          <p className="mx-auto mb-10 lg:col-6">
            If you are looking for a hassle free way to visit an amazing
            Vietnam, then look no further than ArrivalHub!
          </p>
          <div className="flex flex-wrap justify-center">
            {staticData.WhyChoosingArrivalHub.map((items, index) => {
              return (
                <div key={index} className="px-4 md:col-6 lg:col-4">
                  <Img
                    src={items.img}
                    loading="eager"
                    alt="banner"
                    className="mb-6 block h-full w-full object-cover lg:mb-10"
                  />
                  <h5 className="mb-4 font-Montserrat text-lg font-bold lg:mb-6">
                    {items.title}
                  </h5>
                  <p className="mb-8 lg:mb-14">{items.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="relative mb-10 min-h-[250px] bg-arrivalhub-alice-blue-base py-15 lg:mb-20">
        <Img
          src={"/assets/images/home/bg-left.png"}
          width={395}
          height={259}
          alt="bg-left"
          className="mb-6 block h-full w-full object-cover"
          wrapper={{
            className: "absolute bottom-0 left-0 h-full z-0 lg:block hidden",
          }}
        />
        <Img
          src={"/assets/images/home/bg-right.png"}
          width={459}
          height={259}
          alt="bg-right"
          className="mb-6 block h-full w-full object-cover"
          wrapper={{
            className: "absolute bottom-0 right-0 h-full z-0 lg:block hidden",
          }}
        />
        <div className="container relative z-10">
          <LazyLoad>
            <CountUp data={staticData?.countUp} />
          </LazyLoad>
        </div>
      </section>
      <section className="mb-10 lg:mb-15">
        <div className="container">
          <h2 className="mb-6 text-center font-Montserrat  text-3xl font-bold leading-10 text-black lg:text-arrivalhub-title">
            <div className="text-arrivalhub-main">Vietnam</div> Why They Are
            Special
          </h2>
          <div className="mb-10">
            <i className="mb-6 block">
              Have you ever been to Vietnam - one of the most popular tourist
              destinations in Southeast Asia?
            </i>
            <p>
              Vietnam is home to stunning scenery and spectacular nature,
              coupled with cities full of amazing friendly locals. Truly a
              unique and wonderful destination to visit, the majestic mountain
              ranges and breathtaking landscapes make the North of Vietnam to be
              a feast for the eyes of most adventurers. Head to the Central and
              the South, you&apos;re looking for unique experiences linked with
              a wonderful mix of history, culture as well as the stunning,
              tranquil beaches. The small but marvelous
              <b className="text-arrivalhub-main"> S-shaped country </b>has a{" "}
              <b className="text-arrivalhub-main">
                {" "}
                3260km-in-length coastline, 8 World Heritage sites, 54 various
                ethnicities
              </b>{" "}
              and plenty of things to explore and keep you busy while travelling
              in Vietnam.
            </p>
            <p>
              Vietnamese food is undeniably excellent and varied. You&apos;ll
              have a chance to enjoy some of the best food in the world, from
              street food, fresh seafood to delicious Pho, our travellers never
              go hungry in Vietnam. Waiting to be discovered, the country also
              reflects remarkable tradition and cultural diversity where
              wonderful stories and legends will make you feel the spirit,
              connected to the origins of the places. Vietnam is all about what
              must be seen for any history buff.
            </p>
            <p className="mb-0">
              Let&apos;s travel through Vietnam to collect your memories on
              beautiful paths and adventurous journeys in our country.
              Don&apos;t forget to have this fascinating land put between the
              pages of your passport. Plan your trip now!
            </p>
          </div>
          <div className="row">
            {staticData.VietnamWhyTheyAreSpecial.map((item, index) => {
              return (
                <div
                  key={index}
                  className="lg:px-auto col-6 mb-5 overflow-hidden px-[10px] lg:col-4 xl:min-h-[380px]"
                >
                  <div className="relative">
                    <Img
                      src={item.img}
                      loading="lazy"
                      alt={item.title}
                      wrapper={{
                        className: "w-full h-full rounded-lg",
                      }}
                      className="rounded-bl-l block h-full w-full rounded-lg"
                    />
                    <div className="absolute bottom-0 left-0 z-10 h-20 w-full rounded-br-lg rounded-bl-lg bg-black-opacity lg:h-[112px]">
                      <div className="flex h-full w-full items-center px-3 lg:px-8">
                        <p
                          className="m-0 font-Montserrat text-xs font-bold text-white lg:text-2xl"
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="mb-10 lg:mb-20" id="TopDestinations">
        <Map />
      </section>

      <section className="mb-10 lg:mb-20">
        <LazyLoad height={620}>
          <Extracurricular />
        </LazyLoad>
      </section>
      <section className="mb-10 lg:mb-20" id="ThingsToDo">
        <div className="container relative">
          <h2 className="mb-6 text-left font-Montserrat text-3xl font-bold leading-10  lg:text-4xl">
            <span className="text-arrivalhub-main">
              Bucket-List Experiences{" "}
            </span>{" "}
            You Must Try In Vietnam
          </h2>
          <LazyLoad height={782}>
            <Buckets buckets={staticData?.buckets} />
          </LazyLoad>
        </div>
      </section>
      <section id="TravelGuide">
        <div className="container relative">
          <h2 className="mb-10 text-center font-Montserrat text-3xl font-bold leading-10 lg:text-4xl">
            Vietnam Travel{" "}
            <span className="text-arrivalhub-main">Guide & Tips</span> For
            Foreigners
          </h2>
          <div className="row mb-10 justify-center">
            {staticData?.tips.map((item, idx) => {
              return (
                <div key={`item-card-${idx}`} className="mb-10 h-auto lg:col-4">
                  <ItemCard
                    data={item}
                    className="shadow-[0px_16px_40px_rgba(181,181,181,0.25)]"
                    titleProps={{
                      className:
                        "mb-4 text-xl font-bold text-arrivalhub-orange-800",
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="mb-10 lg:mb-20" id="FeaturedStories">
        <LazyLoad height={750}>
          <Stories
            title={"Featured stories"}
            description={
              "Check out inspiring travel reviews from ArrivalHub's customers"
            }
            stories={staticData?.stories}
          />
        </LazyLoad>
      </section>
      <section className="relative h-[calc(100%-80px)]" id="Contact">
        <div className="absolute top-0 left-0 z-10 h-full w-full">
          <Img
            src={"/assets/images/home/background-form.jpg"}
            loading="eager"
            alt="banner"
            wrapper={{ className: "w-full h-full" }}
            className=" h-full w-full object-cover"
          />
        </div>
        <div className="relative z-20 h-full w-full bg-black-opacity ">
          <div className="container pt-10">
            <div className="mx-auto lg:col-8 xl:col-6">
              <div className="mb-6 block text-right lg:hidden">
                <a href={"/"}>
                  <Img
                    src={`/assets/common/logo_white.png`}
                    className="h-full w-full"
                    height={52}
                    width={139}
                    wrapper={{ className: "w-[135px] h-auto" }}
                    alt="logo"
                    priority={true}
                  />
                </a>
              </div>
              <h2 className="mb-6  text-center font-Montserrat text-4xl  font-bold text-white  lg:mb-10   lg:text-arrivalhub-title ">
                Can&apos;t wait to visit Vietnam?{" "}
                <span className="block text-arrivalhub-main lg:inline">
                  Leave us message
                </span>
              </h2>
            </div>
            <div className="row mb-10 flex-col-reverse justify-between lg:flex-row">
              <div className="lg:col-7 xl:col-6">
                <ContactForm />
              </div>
              <div className="text-white lg:col-5">
                <p className="mb-10  lg:mb-20 ">
                  ArrivalHub is one of the top consulting service providers for
                  travelling Vietnam with more than 9 years of experience. Our
                  services are distinguished by dedication, high standard and
                  local expertise. ArrivalHub team&lsquo;s expectation is to
                  bring our customers the most amazing travel experiences in
                  Vietnam while discovering the land&lsquo;s beauty, culture and
                  people.
                </p>
                <p className="font-bold">Arrival Hub Pte. Ltd.</p>
                <div>
                  <a
                    href="https://goo.gl/maps/hvh5S3pJbzTV6cg16"
                    target="_blank"
                    rel="noopener noreferrer "
                    className="mb-6 inline-flex items-start hover:text-cyan-300"
                  >
                    <MdLocationPin
                      size={26}
                      style={{
                        flex: "0 0 26px",
                      }}
                      className="mt-0.5 mr-4 block"
                    />
                    68 Circular Rd, #02-01, Singapore 049422
                  </a>
                </div>
                <div>
                  <div>
                    {" "}
                    <a
                      href="mailto:service@arrivalhub.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mb-6 inline-flex items-start hover:text-cyan-300"
                    >
                      <MdMail
                        size={26}
                        style={{
                          flex: "0 0 26px",
                        }}
                        className="mt-0.5 mr-4 block"
                      />
                      Service@arrivalhub.com
                    </a>
                  </div>
                </div>
                <div>
                  <a
                    href="tel:+65 3138 1666"
                    rel="noopener noreferrer"
                    className="mb-6 inline-flex items-start hover:text-cyan-300"
                  >
                    <MdLocalPhone
                      size={26}
                      style={{
                        flex: "0 0 26px",
                      }}
                      className="mt-0.5 mr-4 block"
                    />
                    SG Contact: +65 3138 1666
                  </a>
                </div>
                <div>
                  <a
                    target="_blank"
                    href="https://api.whatsapp.com/send?phone=84963985050"
                    rel="noopener noreferrer"
                    className="mb-6 inline-flex items-start hover:text-cyan-300"
                  >
                    <MdLocalPhone
                      size={26}
                      style={{
                        flex: "0 0 26px",
                      }}
                      className="mt-0.5 mr-4 block"
                    />
                    VN Contact: +84 9 6398 5050{" "}
                    <RiWhatsappFill className="ml-2 mt-1" />
                  </a>
                </div>
              </div>
            </div>
            {/* footer */}
            <div className="">
              <div className="row items-center border-t border-dashed border-white py-10">
                <div className="text-center text-white lg:col-10 lg:text-left">
                  2022 © ARRIVALHUB PTE. LTD. Copyright All Rights Reserved
                </div>
                <div className="col-2 hidden text-right lg:block">
                  <a href={"/"}>
                    <Img
                      src={`/assets/common/logo_white.png`}
                      className="h-full w-full"
                      height={52}
                      width={139}
                      wrapper={{ className: "w-[135px] h-auto" }}
                      alt="logo"
                      priority={true}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
