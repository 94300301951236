import { useEffect } from "react";

import handleError from "common/error-handle";

function ErrorFallback({ error }) {
  useEffect(() => {
    handleError(error);
  }, []);

  return (
    // <div role="alert">
    //   <p>Something went wrong:</p>
    //   <pre>{error.message}</pre>
    //   <button onClick={resetErrorBoundary}>Try again</button>
    // </div>
    <></>
  );
}

export default ErrorFallback;
