import { Listbox, Transition } from "@headlessui/react";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { BiLoaderAlt } from "react-icons/bi";
import LazyLoad from "react-lazyload";
import styles from "./Index.module.scss";
// import { trigger } from "common/functions";
import { RiSendPlaneFill } from "react-icons/ri";
/*** Vendors ***/

/*** components ***/
const customStyles = {
  menu: (provider, state) => ({
    ...provider,
    fontSize: `14px`,
    // borderColor: 0,
  }),
  menuList: (base) => ({
    ...base,
    whiteSpace: "normal",
    wordWrap: "break-word",
    fontSize: `14px`,
  }),
  control: (base) => ({
    ...base,
    fontSize: "14px",
    cursor: "pointer",
    borderRadius: "6px",
    border: "1px solid #898989",
    padding: "4px 2px",
  }),

  singleValue: (base) => ({
    ...base,
    color: "#898989",
  }),
};
import { InputEmail, InputPhone, InputText } from "components/atoms/Input";
import Toast from "components/atoms/Toast";
import { FiChevronDown } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";
import Select, { components } from "react-select";
/*** content ***/
import { ToastContext } from "common/contexts";
import Img from "components/atoms/Img";

const dataYourInterests = [
  { id: 1, label: "Tour", value: "Tour" },
  { id: 2, label: "Flight", value: "Flight" },
  { id: 3, label: "Hotel", value: "Hotel" },
  { id: 4, label: "Airport Transfer", value: "Airport Transfer" },
];
/*** ====react select====== ***/
const CustomOption = (props) => (
  <components.Option {...props}>
    <div className="flex cursor-pointer items-center">
      {props.data.icon && (
        <Img
          style={{ marginRight: 8, width: 25, height: 18 }}
          src={`${props.data.icon.toLowerCase()}.svg`}
          alt={`${props.data.name.toLowerCase()}`}
        />
      )}
      <span className="text-sm">{props.data.name || props.data.label}</span>
    </div>
  </components.Option>
);

/*** ====react select styled====== ***/

const customSelectStylesPhoneCode = {
  control: (base) => ({
    ...base,
    height: 42,
    minHeight: 42,
    fontSize: `14px`,
    margin: "0 ",
  }),
  menu: (base) => ({
    ...base,
    fontSize: `14px`,
  }),
};

const IconSearch = () => (
  <FiSearch className=" mr-2 text-arrivalhub-gray-500" />
);
//*Dropdown Select - START */
const DropdownSelect = ({ children, isOpen, target, onClose, ...props }) => (
  <div className={`relative bg-white ${props.className && props.className}`}>
    {target}
    {isOpen ? (
      <div
        className="absolute z-20 w-full rounded-sm bg-white pt-2 shadow"
        style={{ height: `70px` }}
      >
        {children}
      </div>
    ) : null}
    {isOpen ? (
      <div
        className="fixed bottom-0 left-0 top-0 right-0 z-10"
        onClick={onClose}
      />
    ) : null}
  </div>
);
function trigger(data) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "contact_form",
    // full_name: data.first_name,
    first_name: data.first_name,
  });
}
const Component = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    setValue,
    formState: { isSubmitting, isSubmitted, isSubmitSuccessful, errors },
  } = useForm({ mode: "onSubmit" });
  const {
    title,
    description,
    fullForm,
    isLazyLoad,
    subject,
    callback,
    ...otherProps
  } = props;
  const recaptchaRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const { openAlert } = useContext(ToastContext);
  const [phoneCodeList, setPhoneCodeList] = useState([]);
  const [phoneCountry, setPhoneCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [interests, setInterests] = useState();
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.NEXT_PUBLIC_API_FORM}/api/onboarding/country`)
      .then((result) => {
        let phoneList = result?.data?.data
          ?.filter((item) => item.is_country)
          .map((item) => ({
            label: item.country_code + " +" + item.phone_code,
            value: item.id,
          }));

        setPhoneCodeList(phoneList);
      });
  }, []);

  //data geoLooking

  useEffect(() => {
    axios
      .get(`${process.env.NEXT_PUBLIC_API_FORM}/api/helper/geoLooking`)

      .then((result) => {
        setPhoneCountry(result?.data?.data?.id);
      });
  }, []);

  // data country
  useEffect(() => {
    axios
      .get(`${process.env.NEXT_PUBLIC_API_FORM}/api/v2a/services/countries`)

      .then((result) => {
        let countryList = result?.data?.data.map((item) => ({
          label: item.name,
          value: item.id,
          flagSrc: item.country_code.toLowerCase(),
        }));

        setCountries(countryList);
      });
  }, []);

  // select phone code
  const handleChangePhoneCountry = (newValue) => {
    setValue("phone_country_id", newValue?.value, { shouldValidate: true });
    setPhoneCountry(newValue?.value);
    setIsOpenDropdown(!isOpenDropdown);
  };
  const onSelectCountryChange = (newCountry) => {
    setValue("country_id", newCountry.value, { shouldValidate: true });
    setCountry(newCountry);
  };

  const onSelectInterests = (newyInterests) => {
    setValue("subject", newyInterests.label, { shouldValidate: true });
    setInterests(newyInterests);
  };
  // renderCountryListbox
  const renderCountryListbox = () => {
    let listCountryFiltered = [...countries];
    // const locationRouter = "";
    // if (locationRouter === "offshore") {
    //   listCountryFiltered = countries.filter(
    //     (item) => !["Hong Kong", "Singapore", "Vietnam"].includes(item.value)
    //   );
    // }
    return listCountryFiltered.map((item, itemIdx) => (
      <Listbox.Option key={itemIdx} value={item} className="list-none">
        <div
          className={`flex cursor-pointer items-center gap-x-3 p-2 font-medium  ${
            country?.label === item.label
              ? " bg-arrivalhub-dodger-blue-600 text-white"
              : "hover:bg-arrivalhub-lavender-base  "
          }`}
          onClick={() => setCountry(item)}
        >
          {item?.flagSrc && (
            <Img
              src={`/assets/flags/1x1/${item.flagSrc}.svg`}
              width={24}
              height={24}
              className="min-h-[24px] min-w-[24px] rounded-full"
              alt={item.flagSrc}
            />
          )}
          <span className="truncate text-sm">{item.label}</span>
        </div>
      </Listbox.Option>
    ));
  };
  const sendContact = async (formData, token) => {
    const endpoint = `${process.env.NEXT_PUBLIC_API_FORM}/api/portal/contact_form`;
    const headers = {
      "Content-Type": "application/json",
      "captcha-response": token,
    };
    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(formData),
    };
    const response = await fetch(endpoint, options);
    return await response.json();
  };

  const onSubmit = async (formData) => {
    const token = await recaptchaRef.current.executeAsync();
    if (token) {
      const data = {
        website_id: 9,
        contact_from_id: 5,
        first_name: formData.full_name,
        email: formData.email,
        phone_country_id: phoneCountry,
        phone_number: formData.phone_number,
        message: formData.message || " ",
        country_id: country.value,
        subject: formData.subject,
      };

      try {
        sendContact(data, token)
          .then((response) => {
            if (response.state) {
              trigger(data);
              openAlert(3000, "success", {
                title: "success",
              });
              reset();
            }
          })
          .catch((e) => {
            if (e) {
              clearErrors();
              openAlert(3000, "error", {
                title: "Error",
                description: "Can't send contact info",
              });
              console.log("error", e);
              setIsLoading(false);
            }
          });
      } catch (e) {
        clearErrors();
        openAlert(3000, "error", {
          title: "Error",
          description: "Không thể gửi đi thông tin liên hệ",
        });
        console.log("error", e);
        setIsLoading(false);
      }
    }
  };

  return (
    <div {...otherProps}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="rounded-lg bg-white p-6 lg:py-10 lg:px-12 "
      >
        <div className="row mb-4 lg:mb-6 ">
          {/* Full name */}
          <div className={""}>
            <InputText
              name_label="Full name"
              variant={"light"}
              name="full_name"
              placeholder={"Ade Tiger"}
              register={register}
              errors={errors}
              requiredText="This field is required"
              othersClass="mb-4 "
            />
          </div>
          {/* Email address */}
          <div className="">
            <InputEmail
              name_label="Email address"
              variant="light"
              name="email"
              placeholder={"yourname@email.com"}
              register={register}
              errors={errors}
              requiredText="This field is required"
              othersClass="mb-4 "
            />
          </div>
          {/* Nationality */}
          <div className="">
            <label className="mb-1 inline-block text-arrivalhub-gray-500">
              Nationality
            </label>
            <div className="mb-4">
              <Listbox
                as="div"
                value={country}
                onChange={onSelectCountryChange}
                className="relative w-full   rounded border border-arrivalhub-gray-500 px-4 py-3"
              >
                {({ open }) => (
                  <>
                    <Listbox.Button
                      as="div"
                      role="button"
                      className="relative h-full w-full select-none pr-9 text-left focus:outline-0"
                    >
                      <div className="flex items-center gap-x-2 ">
                        {country?.flagSrc && (
                          <Img
                            src={`/assets/flags/1x1/${country.flagSrc}.svg`}
                            width={24}
                            height={24}
                            className="min-h-[24px] min-w-[24px] rounded-full"
                            alt={country.flagSrc}
                          />
                        )}
                        {country?.label ? (
                          <span className=" truncate tracking-[0.25px] text-arrivalhub-gray-500">
                            {country?.label}
                          </span>
                        ) : (
                          <span className="truncate text-sm tracking-[0.25px]  text-arrivalhub-gray-500">
                            Select Location
                          </span>
                        )}

                        <FiChevronDown
                          className={` absolute right-1 top-1/2 float-right -translate-y-1/2 transform align-middle ${
                            open ? "rotate-180" : "rotate-0"
                          } text-arrivalhub-gray-500 transition-all duration-200`}
                          size={18}
                        />
                      </div>
                    </Listbox.Button>
                    <Transition
                      show={open}
                      leave="transition-all duration-200 ease-out"
                      leaveFrom="transform opacity-50"
                      leaveTo="transform opacity-0"
                      className="absolute left-0 top-11 z-99 mt-2 w-full"
                    >
                      <div className="overflow-hidden rounded-md border border-arrivalhub-gray-300 shadow-xl">
                        <Listbox.Options className="max-h-60 overflow-auto bg-white focus:outline-none ">
                          {renderCountryListbox()}
                        </Listbox.Options>
                      </div>
                    </Transition>
                  </>
                )}
              </Listbox>
              {!country && isSubmitted && !isSubmitSuccessful && (
                <span className="block pt-2 text-sm text-red-500">
                  This field is required
                </span>
              )}
            </div>
          </div>
          {/* Phone number */}
          <div className="mb-4 ">
            <label className="mb-1 inline-block text-arrivalhub-gray-500">
              Phone number
            </label>
            <div className="relative flex w-full  lg:space-x-2">
              <DropdownSelect
                isOpen={isOpenDropdown}
                onClose={() => setIsOpenDropdown(!isOpenDropdown)}
                className="mr-2  w-5/12 md:w-4/12  lg:w-3/12 "
                target={
                  <div
                    role="button"
                    className="h- relative block w-full cursor-pointer rounded border border-arrivalhub-gray-500 bg-white py-3 pl-3 pr-2"
                    onClick={() => setIsOpenDropdown(!isOpenDropdown)}
                  >
                    {phoneCodeList &&
                    phoneCodeList.find(
                      (item) => item.value === phoneCountry
                    ) ? (
                      <div className="flex items-center">
                        <span className=" text-sm  text-arrivalhub-gray-600">
                          {
                            phoneCodeList.find(
                              (item) => item.value === phoneCountry
                            ).label
                          }
                        </span>
                        <FiChevronDown
                          className={` absolute right-4  ${
                            isOpenDropdown ? "rotate-180" : "rotate-0"
                          } text-arrivalhub-gray-500 transition-all duration-200`}
                          size={16}
                        />
                      </div>
                    ) : (
                      "Select..."
                    )}
                  </div>
                }
              >
                <Select
                  instanceId="phone-country-code"
                  autoFocus
                  className={styles.customSelectSearch}
                  backspaceRemovesValue={false}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  menuIsOpen
                  options={phoneCodeList}
                  placeholder="Search..."
                  tabSelectsValue={false}
                  value={
                    phoneCodeList &&
                    phoneCodeList.find((item) => item.value === phoneCountry)
                  }
                  styles={customSelectStylesPhoneCode}
                  components={{
                    Option: CustomOption,
                    IndicatorSeparator: () => null,
                    DropdownIndicator: IconSearch,
                  }}
                  onChange={handleChangePhoneCountry}
                />
              </DropdownSelect>

              <InputPhone
                name_label="Phone number"
                variant="light"
                name="phone_number"
                placeholder={"800 2738 9700"}
                register={register}
                errors={errors}
                requiredText="This field is required"
                othersClass="w-7/12 md:w-8/12 lg:mr-2 lg:w-9/12"
              />
            </div>
          </div>
          {/* Your interests */}
          <div className="mb-4 ">
            <label className="mb-1 inline-block text-arrivalhub-gray-500">
              Your interests
            </label>
            <Select
              instanceId="interests"
              isSearchable={false}
              options={dataYourInterests}
              styles={customStyles}
              placeholder="Select Service"
              onChange={onSelectInterests}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
            {!interests && isSubmitted && !isSubmitSuccessful && (
              <span className="block pt-2 text-sm text-red-500">
                This field is required
              </span>
            )}
          </div>
          {/* Message */}
          <div className="">
            <div className="">
              <p className=" mb-1 text-gray-500">Message</p>
              <textarea
                className="w-full rounded-md border border-arrivalhub-gray-500 "
                defaultValue=""
                rows={5}
                cols={20}
                {...register("message")}
                disabled={isSubmitting}
              />
            </div>
          </div>
        </div>
        <div className="justify-between text-center xl:flex">
          <div className=" mx-auto mb-6 flex justify-center  lg:col-6 xl:m-0 xl:mb-0 xl:justify-start">
            <LazyLoad>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.NEXT_PUBLIC_G_RECAPTCHA_V3_SITE_KEY}
                badge="inline"
                size="invisible"
              />
            </LazyLoad>
          </div>
          <button
            type="submit"
            id="form-contact-us-button"
            disabled={isLoading}
            className="inline-block items-center justify-center rounded bg-arrivalhub-gray-800 px-6 py-4 font-bold text-white"
            onClick={() => {
              if (isSubmitting) setIsLoading(true);
            }}
          >
            {isSubmitting && (
              <BiLoaderAlt
                className="mr-2 inline-block animate-spin"
                size={24}
              />
            )}
            Send Us
            <RiSendPlaneFill
              size={20}
              className="ml-2 mb-0.5 inline-block text-white"
            />
          </button>
        </div>
      </form>
    </div>
  );
};

function ContactForm(props) {
  return (
    <Toast>
      <Component {...props} />
    </Toast>
  );
}

import { withErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/common/ErrorFallback";

export default withErrorBoundary(ContactForm, {
  FallbackComponent: ErrorFallback,
});
