/* eslint-disable react/display-name */
/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";

import Img from "components/atoms/Img";

import styles from "./index.module.scss";

const Arrow = ({ direction, handleClick, wrapperProps, ...props }) => {
  const { className, ...otherWrapperProps } = wrapperProps;

  let Icon;
  switch (direction) {
    case "right":
      Icon = (props) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          className={`${props.className ? props.className : "h-6 w-6"}`}
        >
          <path
            fill={props.color}
            d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
          />
        </svg>
      );
      break;
    case "left":
      Icon = (props) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          className={`${props.className ? props.className : "h-6 w-6"}`}
        >
          <path
            fill={props.color}
            d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
          />
        </svg>
      );
      break;

    default:
      break;
  }

  const onArrowClick = (e) => {
    e.preventDefault();
    handleClick && handleClick();
  };

  return (
    <div
      onClick={(e) => onArrowClick(e)}
      data-direction={direction}
      className={`${className || styles["slider-arrow"]}`}
      {...otherWrapperProps}
    >
      <Icon {...props} />
    </div>
  );
};

Arrow.propTypes = {
  handleClick: PropTypes.func,
  direction: PropTypes.string,
  wrapperProps: PropTypes.object,
};

Arrow.defaultProps = {
  handleClick: () => {},
  direction: "right",
  wrapperProps: {},
};

export default Arrow;
