// import PropTypes, { number } from "prop-types";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
/**
 * vendor
 */
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import Slider from "../Slider";
/**
 * components
 */
import Img from "components/atoms/Img";
const ImgZoom = ({ number, className, wrapper }) => {
  return (
    <a
      data-fancybox="gallery"
      href={`assets/images/home/gallery/gallery-zom-${number}.png`}
      className={className}
    >
      <Img
        src={`assets/images/home/gallery/gallery-${number}.png`}
        className="h-full w-full select-none rounded-lg"
        wrapper={{ className: wrapper }}
      />
    </a>
  );
};
const Extracurricular = () => {
  const [showMore, setShowMore] = useState(null);

  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {});
  }, []);

  useEffect(() => {
    if (showMore === true) {
      const bottomContent = document.getElementById(`bottomContent`);
      const bottomRect = bottomContent.getBoundingClientRect();

      window.scrollTo({
        left: 0,
        top: window.pageYOffset + bottomRect.top - 200,
        behavior: "smooth",
      });
      // bottomContent.scrollIntoView({ behavior: "smooth", block: "start" });
    } else if (showMore === false) {
      const topContent = document.getElementById(`topContent`);
      const topRect = topContent.getBoundingClientRect();

      window.scrollTo({
        left: 0,
        top: window.pageYOffset + topRect.top - 200,
        behavior: "smooth",
      });
      // topContent.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [showMore]);

  const handleClick = (e) => {
    if (showMore === null) {
      setShowMore(true);
    } else {
      setShowMore(!showMore);
    }
  };

  return (
    <>
      <div className="container">
        <h2 className="mb-6 text-center font-Montserrat text-3xl font-bold leading-10 text-black lg:mb-10 lg:text-arrivalhub-title">
          Vietnam Gallery
        </h2>
      </div>
      <Slider
        size="full"
        wrapperProps={{ className: "xl:block hidden h-full w-full" }}
        className="absolute overflow-visible pt-0 pb-0"
        inContainer
        settings={{
          initialSlide: 1,
          dots: false,
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          spaceBetween: 20,
          infinite: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                dots: true,
                arrows: false,
                spaceBetween: 30,
              },
            },
            {
              breakpoint: 990,
              settings: {
                dots: true,
                arrows: false,
                spaceBetween: 30,
              },
            },
          ],
        }}
        slideProps={{
          className: "opacity-25 transition-opacity duration-100 ease-out",
          activeClassName: "!opacity-100",
        }}
        arrowProps={{
          wrapperProps: {
            className: styles["extracurricular-slide-arrows"],
          },
          className: "bg-arrivalhub-orange-800 w-10 h-10 rounded-full p-2",
          color: "white",
        }}
        dotsProps={{
          wrapperProps: {
            className: styles["extracurricular-slide-dots"],
          },
          className: styles["slider-dot"],
          activeClassName: styles["slider-dot-active"],
        }}
      >
        <div className="row">
          <div className="col-12">
            <ImgZoom
              number={3.1}
              className="mb-4 block h-full w-full lg:mb-0"
            />
          </div>
        </div>
        <div className="row px-[5px]">
          <div className="col-4 px-[10px]">
            <ImgZoom number={3.2} className="mb-1 block" />
            <ImgZoom number={3.3} />
          </div>
          <div className="col-4 px-[10px]">
            <ImgZoom number={3.4} className="mb-1 block" />
            <ImgZoom number={3.5} />
          </div>
          <div className="col-4 px-[10px]">
            <ImgZoom number={3.6} className="mb-1 block" />
            <ImgZoom number={3.7} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ImgZoom number={1} className="mb-4 block h-full w-full lg:mb-0" />
          </div>
        </div>
        <div className="row px-[5px]">
          <div className="col-4 px-[10px]">
            <ImgZoom number={2} className="mb-1 block" />
            <ImgZoom number={3} />
          </div>
          <div className="col-4 px-[10px]">
            <ImgZoom number={4} className="mb-1 block" />
            <ImgZoom number={5} />
          </div>
          <div className="col-4 px-[10px]">
            <ImgZoom number={6} className="mb-1 block" />
            <ImgZoom number={7} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ImgZoom
              number={2.1}
              className="mb-4 block h-full w-full lg:mb-0"
            />
          </div>
        </div>
        <div className="row px-[5px]">
          <div className="col-4 px-[10px]">
            <ImgZoom number={2.2} className="mb-1 block" />
            <ImgZoom number={2.3} />
          </div>
          <div className="col-4 px-[10px]">
            <ImgZoom number={2.4} className="mb-1 block" />
            <ImgZoom number={2.5} />
          </div>
          <div className="col-4 px-[10px]">
            <ImgZoom number={2.6} className="mb-1 block" />
            <ImgZoom number={2.7} />
          </div>
        </div>
      </Slider>
      <div className="container xl:hidden">
        <div id="topContent" className="row mb-6">
          <div className="col-12">
            <ImgZoom number={1} className="block h-full w-full lg:mb-0" />
          </div>
        </div>
        <div className="row mb-6 px-[5px]">
          <div className="lg:px-auto col-4 px-[10px]">
            <ImgZoom number={2} className="mb-1 block" />
            <ImgZoom number={3} />
          </div>
          <div className="lg:px-auto col-4 px-[10px]">
            <ImgZoom number={4} className="mb-1 block" />
            <ImgZoom number={5} />
          </div>
          <div className="lg:px-auto col-4 px-[10px]">
            <ImgZoom number={6} className="mb-1 block" />
            <ImgZoom number={7} />
          </div>
        </div>
        <div
          className={`${showMore === true ? "block" : "hidden"}`}
          id="bottomContent"
        >
          <div className="row mb-6">
            <div className="col-12">
              <ImgZoom number={2.1} className="block h-full w-full lg:mb-0" />
            </div>
          </div>
          <div className="row mb-6 px-[5px]">
            <div className="col-4 px-[10px]">
              <ImgZoom number={2.2} className="mb-1 block" />
              <ImgZoom number={2.3} />
            </div>
            <div className="col-4 px-[10px]">
              <ImgZoom number={2.4} className="mb-1 block" />
              <ImgZoom number={2.5} />
            </div>
            <div className="col-4 px-[10px]">
              <ImgZoom number={2.6} className="mb-1 block" />
              <ImgZoom number={2.7} />
            </div>
          </div>
          <div className="row mb-6">
            <div className="col-12">
              <ImgZoom number={3.1} className="block h-full w-full lg:mb-0" />
            </div>
          </div>
          <div className="row mb-6 px-[5px]">
            <div className="col-4 px-[10px]">
              <ImgZoom number={3.2} className="mb-1 block" />
              <ImgZoom number={3.3} />
            </div>
            <div className="col-4 px-[10px]">
              <ImgZoom number={3.4} className="mb-1 block" />
              <ImgZoom number={3.5} />
            </div>
            <div className="col-4 px-[10px]">
              <ImgZoom number={3.6} className="mb-1 block" />
              <ImgZoom number={3.7} />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            className="rounded-md border border-arrivalhub-orange-800 px-4 py-2 font-semibold text-arrivalhub-orange-800"
            onClick={(e) => handleClick(e)}
          >
            {showMore ? "Show Less" : "Show More"}
          </button>
        </div>
      </div>
    </>
  );
};

Extracurricular.propTypes = {};

Extracurricular.defaultProps = {};

import { withErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/common/ErrorFallback";

export default withErrorBoundary(Extracurricular, {
  FallbackComponent: ErrorFallback,
});
