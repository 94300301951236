/* eslint-disable react-hooks/rules-of-hooks */
import {
  useState,
  useEffect,
  useRef,
  useCallback,
  Children,
  cloneElement,
  isValidElement,
} from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import Container from "./Container";

import SliderContent from "./SliderContent";
import Slide from "./Slide";
import Arrow from "./Arrow";
import Dots from "./Dots";

import { withErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/common/ErrorFallback";

const IntervalWithStop = ({ callback, delay, isPlaying }) => {
  const savedCallback = useRef(null);
  const intervalId = useRef(null);
  const [currentDelay, setCurrentDelay] = useState(delay);

  const toggleRunning = useCallback(
    () =>
      setCurrentDelay((currentDelayVar) =>
        currentDelayVar === null ? delay : null
      ),
    [delay]
  );

  const clear = useCallback(() => clearInterval(intervalId.current), []);

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (intervalId.current) clear();

    if (currentDelay !== null) {
      intervalId.current = setInterval(tick, currentDelay);
    }

    return clear;
  }, [currentDelay, clear]);

  useEffect(() => {
    toggleRunning();
  }, [isPlaying]);

  // return [toggleRunning, !!currentDelay];
  return <></>;
};

const Slider = ({
  className,
  inContainer = false,
  size = "medium",
  arrowProps,
  dotsProps,
  slideProps,
  sliderWrapperProps,
  sliderProps,
  children,
  settings,
  wrapperProps,
}) => {
  const [width, setWidth] = useState(0);
  const [internalSettings, setInternalSettings] = useState({});
  const [slideWidth, setSlideWidth] = useState(0);
  const [slideHeight, setSlideHeight] = useState(0);
  const [slides, setSlides] = useState([]);
  const [dots, setDots] = useState([]);
  const transitionRef = useRef();
  const resizeRef = useRef();

  const containerRef = useRef(null);

  const [firstSlides, setFirstSlides] = useState([]);
  const [lastSlides, setLastSlides] = useState([]);
  const [modifiedSlides, setModifiedSlides] = useState([]);

  const [maxTranslate, setMaxTranslate] = useState(0);
  const [minTranslate, setMinTranslate] = useState(0);

  const [state, setState] = useState({
    activeSlide: 0,
    translate: 0,
    transition: 0.45,
  });
  const { activeSlide, translate, transition } = state;

  const [playing, setPlaying] = useState(false);

  const SIZES = {
    small: "h-1/3",
    medium: "h-1/2",
    full: "h-full",
    large: "h-screen",
  };

  const defaultSettings = {
    dots: true,
    arrows: true,
    autoPlay: false,
    speed: 3000,
    infinite: true,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    effect: "scroll", //fade | flip
    spaceBetween: 0,
    dragable: false,
    vertical: false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (currentIndex, nextIndex) => {},
    afterChange: (newIndex) => {},
  };

  useEffect(() => {
    const newSettings = Object.assign({}, defaultSettings, settings);
    setInternalSettings(newSettings);
  }, [settings]);

  useEffect(() => {
    if (Children.count(children) !== 0) {
      setSlides(Children.toArray(children));
    }
  }, [children]);

  // Render slides
  useEffect(() => {
    if (slides.length !== 0) {
      renderLoop(slides, internalSettings);
    }
  }, [slides, internalSettings, slideWidth, slideHeight]);

  // Check responsive & Update slide when resize
  useEffect(() => {
    const allSettings = { ...defaultSettings, ...settings };
    if (allSettings.responsive) {
      const responsives = [...allSettings.responsive];
      const breakpoints = responsives.filter((x) => x.breakpoint >= width);
      const breakpoint =
        Array.isArray(breakpoints) && breakpoints.length > 0
          ? breakpoints.reduce((prev, curr) => {
              return prev.breakpoint < curr.breakpoint ? prev : curr;
            })
          : undefined;
      const breakpointSettings = breakpoint ? breakpoint.settings : settings;

      setInternalSettings({ ...allSettings, ...breakpointSettings });
    } else {
      setInternalSettings({ ...allSettings });
    }
  }, [width]);

  // Update slides when settings change
  useEffect(() => {
    // Update dot list when SlideToShow change
    if (internalSettings.slidesToShow) {
      const listDot = [];
      if (slides.length > internalSettings.slidesToShow) {
        for (let i = 0; i <= getDotCount(); i += 1) {
          listDot.push({ slide: slides[i] });
        }
      }
      setDots(listDot);
    }
    // Stop or Play slide automatic
    if (internalSettings.autoPlay) {
      setPlaying(true);
    }
  }, [internalSettings]);

  useEffect(() => {
    if (internalSettings.afterChange) {
      internalSettings.afterChange(activeSlide);
    }
  }, [activeSlide]);

  // Render slides with duplicates - START -
  const calcLoopSlides = (slides, staticSettings) => {
    let loopedSlides = Math.ceil(parseFloat(staticSettings.slidesToShow, 10));

    loopedSlides += staticSettings.loopAddSlides || 0;

    if (loopedSlides > slides.length) {
      loopedSlides = slides.length;
    }

    return loopedSlides;
  };

  const renderLoop = (slides, staticSettings) => {
    const modifiedSlides = slides.map((child, index) => {
      return cloneElement(child, {
        "data-slide-index": index,
      });
    });

    function duplicateSlide(child, index, position) {
      return cloneElement(child, {
        key: `${child.key}-duplicate-${index}-${position}`,
        className: `${child.props.className || ""} slide-duplicate`,
        style: { ...child.props.style },
      });
    }

    const loopedSlides = calcLoopSlides(modifiedSlides, staticSettings);

    const prependSlides = [];
    const appendSlides = [];
    if (staticSettings.infinite) {
      for (let i = 0; i < loopedSlides; i += 1) {
        const index =
          i - Math.floor(i / modifiedSlides.length) * modifiedSlides.length;
        appendSlides.push(duplicateSlide(modifiedSlides[index], i, "append"));
        prependSlides.unshift(
          duplicateSlide(
            modifiedSlides[modifiedSlides.length - index - 1],
            i,
            "prepend"
          )
        );
      }
    }

    let min = prependSlides.length * slideWidth;

    let max = (prependSlides.length + modifiedSlides.length) * slideWidth;

    if (staticSettings.vertical) {
      min = prependSlides.length * slideHeight;
      max = (prependSlides.length + modifiedSlides.length) * slideHeight;

      setMinTranslate(min);
      setMaxTranslate(max - staticSettings.slidesToShow * slideHeight);
    } else {
      setMinTranslate(min);
      setMaxTranslate(max - staticSettings.slidesToShow * slideWidth);
    }

    setFirstSlides(prependSlides);
    setLastSlides(appendSlides);
    setModifiedSlides([...prependSlides, ...modifiedSlides, ...appendSlides]);
    //return [...prependSlides, ...modifiedSlides, ...appendSlides];
  };
  // Render slides with duplicates - END -

  // Set max dots
  const getDotCount = function () {
    let pagerNum = 0;

    pagerNum = Math.ceil(
      (slides.length - internalSettings.slidesToShow) /
        internalSettings.slidesToScroll
    );

    return pagerNum;
  };

  const handleResize = () => {
    // setState({
    //   ...state,
    //   translate: 0,
    //   transition: 0,
    // });
    goToSlide(0);
  };

  // Translate smoothly when resize window - START
  const smoothTransition = () => {
    let scrollPosition = translate;

    if (activeSlide === getDotCount()) {
      scrollPosition = maxTranslate;
    } else if (activeSlide === 0) {
      scrollPosition = minTranslate;
    } else {
      if (internalSettings.vertical) {
        scrollPosition = minTranslate + activeSlide * slideHeight;
      } else {
        scrollPosition = minTranslate + activeSlide * slideWidth;
      }
    }

    setState({
      ...state,
      transition: 0,
      translate: scrollPosition,
    });
  };
  // Translate smoothly when resize window - END

  // Go to next slide
  const nextSlide = async () => {
    const slidesToScroll = internalSettings?.slidesToScroll || 1;
    const slidesToShow = internalSettings?.slidesToShow || 1;

    // Get position (px) will translate
    // Bằng chiều rộng của một slide nhân với số lượng slide muốn scroll
    let step = slideWidth * slidesToScroll;

    // Vertical
    if (internalSettings.vertical) {
      step = slideHeight * slidesToScroll;
    }

    if (internalSettings.beforeChange) {
      // Đã đến vị trí cuối cùng
      if (activeSlide + slidesToShow > slides.length - 1) {
        internalSettings.beforeChange(
          activeSlide,
          internalSettings.infinite ? 0 : getDotCount()
        );
      }
      // Chưa đến vị trí cuối cùng
      else {
        internalSettings.beforeChange(
          activeSlide,
          activeSlide + slidesToScroll
        );
      }
    }

    // Đã đến vị trí cuối cùng
    if (activeSlide + slidesToShow > slides.length - 1) {
      setState({
        ...state,
        translate: internalSettings.infinite ? maxTranslate + step : translate,
        activeSlide: internalSettings.infinite ? 0 : getDotCount(),
      });
    }
    // Chưa đến vị trí cuối cùng
    else {
      setState({
        ...state,
        translate: translate + step,
        activeSlide: activeSlide + slidesToScroll,
      });
    }
  };

  // Go to previous slide
  const prevSlide = async () => {
    const slidesToScroll = internalSettings?.slidesToScroll || 1;
    const slidesToShow = internalSettings?.slidesToShow || 1;

    // Get position (px) will translate
    // Bằng chiều rộng của một slide nhân với <slidesToScroll>
    let step = slideWidth * slidesToScroll;

    // Vertical
    if (internalSettings.vertical) {
      step = slideHeight * slidesToScroll;
    }

    if (internalSettings.beforeChange) {
      // Đã đến vị trí ban đầu
      if (activeSlide <= 0) {
        internalSettings.beforeChange(
          activeSlide,
          internalSettings.infinite ? getDotCount() : 0
        );
      }
      // Chưa đến vị trí ban đầu
      else {
        internalSettings.beforeChange(
          activeSlide,
          activeSlide - slidesToScroll
        );
      }
    }

    // Đã đến vị trí ban đầu
    if (activeSlide <= 0) {
      setState({
        ...state,
        translate: internalSettings.infinite ? minTranslate - step : 0,
        activeSlide: internalSettings.infinite ? getDotCount() : 0,
      });
    }
    // Chưa đến vị trí ban đầu
    else {
      setState({
        ...state,
        translate: translate - step,
        activeSlide: activeSlide - slidesToScroll,
      });
    }
  };

  const goToSlide = (index) => {
    if (internalSettings.beforeChange) {
      internalSettings.beforeChange(activeSlide, index);
    }

    // Change slide vertical
    if (internalSettings.vertical) {
      setState({
        ...state,
        translate: (index + firstSlides.length) * slideHeight,
        activeSlide: index,
      });
    } else {
      setState({
        ...state,
        translate: (index + firstSlides.length) * slideWidth,
        activeSlide: index,
      });
    }
  };

  const setSlideStyle = (staticSettings) => {
    // Vertical styles
    if (staticSettings.vertical) {
      return {
        width: `${slideWidth - staticSettings.spaceBetween}px`,
        marginTop: `${
          staticSettings.spaceBetween ? staticSettings.spaceBetween / 2 : 0
        }px`,
        marginBottom: `${
          staticSettings.spaceBetween ? staticSettings.spaceBetween / 2 : 0
        }px`,
      };
    } else {
      return {
        width: `${slideWidth - staticSettings.spaceBetween}px`,
        marginLeft: `${
          staticSettings.spaceBetween ? staticSettings.spaceBetween / 2 : 0
        }px`,
        marginRight: `${
          staticSettings.spaceBetween ? staticSettings.spaceBetween / 2 : 0
        }px`,
        transition: `${
          staticSettings.effect === "scroll"
            ? "none"
            : "opacity 600ms ease-in-out"
        }`,
      };
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    const containerStyle = window.getComputedStyle
      ? getComputedStyle(container, null)
      : container.currentStyle;

    const paddingRight = parseInt(containerStyle.paddingRight) || 0;
    const paddingLeft = parseInt(containerStyle.paddingLeft) || 0;

    // const containerWidth = container.clientWidth;
    const containerWidth = container.clientWidth - (paddingLeft + paddingRight);

    setWidth(containerWidth - internalSettings.spaceBetween);
    setSlideWidth(containerWidth / internalSettings.slidesToShow);

    transitionRef.current = smoothTransition;
    resizeRef.current = handleResize;
  });

  useEffect(() => {
    setState({
      ...state,
      translate: 0,
    });
    const smooth = (e) => {
      if (
        e.target &&
        typeof e.target.className === "string" &&
        e.target.className.includes("slider-content")
      ) {
        transitionRef.current();
      }
    };

    const resize = () => {
      resizeRef.current();
    };

    const transitionEnd = window.addEventListener("transitionend", smooth);
    const onResize = window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("transitionend", transitionEnd);
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    if (transition === 0) setState({ ...state, transition: 0.3 });
  }, [transition]);

  const Element = inContainer ? Container : "div";

  return (
    <div
      className={`overflow-hidden ${wrapperProps?.className || ""}`}
      id={wrapperProps?.id || null}
    >
      <div className={`relative h-full w-full`}>
        <Element
          ref={containerRef}
          className={cn(
            SIZES[size],
            "whitespace-no-wrap relative m-0 w-full overflow-hidden",
            className
          )}
          style={{
            height: internalSettings.vertical
              ? `${slideHeight * internalSettings.slidesToShow}px`
              : "100%",
          }}
          {...sliderProps}
        >
          <SliderContent
            effect={internalSettings.effect}
            translate={translate}
            transition={transition}
            width={
              internalSettings.effect === "scroll"
                ? slideWidth * slides.length
                : width
            }
            nextCallback={nextSlide}
            prevCallback={prevSlide}
            dragable={internalSettings.dragable}
            vertical={internalSettings.vertical}
            {...sliderWrapperProps}
          >
            {modifiedSlides.length !== 0 &&
              modifiedSlides.map((element, idx) => {
                const { className, ...otherSlideProps } = slideProps;
                return (
                  <Slide
                    key={idx}
                    style={setSlideStyle(internalSettings)}
                    isActive={
                      idx >= activeSlide + firstSlides.length &&
                      idx <
                        activeSlide +
                          internalSettings.slidesToShow +
                          firstSlides.length
                    }
                    className={`${className || ""}${
                      element.props.className || ""
                    }`}
                    data-slide-index={element.props["data-slide-index"]}
                    onMouseOver={() => setPlaying(false)}
                    onMouseLeave={() => setPlaying(true)}
                    passSlideHeight={(height) => {
                      // Update slide height
                      setSlideHeight(height + internalSettings.spaceBetween);
                    }}
                    {...otherSlideProps}
                  >
                    {element}
                  </Slide>
                );
              })}
          </SliderContent>
          {internalSettings.arrows === true && (
            <>
              <Arrow direction="left" handleClick={prevSlide} {...arrowProps} />
              <Arrow
                direction="right"
                handleClick={nextSlide}
                {...arrowProps}
              />
            </>
          )}
        </Element>
        {isValidElement(internalSettings.arrows) && (
          <>{internalSettings.arrows}</>
        )}
        {internalSettings.dots && (
          <Dots
            dots={dots}
            activeSlide={activeSlide}
            handleClick={(index) => goToSlide(index)}
            {...dotsProps}
          />
        )}
        {internalSettings.autoPlay && (
          <IntervalWithStop
            callback={nextSlide}
            delay={internalSettings.speed}
            isPlaying={playing}
          />
        )}
      </div>
    </div>
  );
};

Slider.propTypes = {
  slides: PropTypes.array,
  slideInterval: PropTypes.number,
  className: PropTypes.string,
  size: PropTypes.string,
  inContainer: PropTypes.bool,
  arrowProps: PropTypes.object,
  dotsProps: PropTypes.object,
  slideProps: PropTypes.object,
  sliderWrapperProps: PropTypes.object,
  sliderProps: PropTypes.object,
};

Slider.defaultProps = {
  slideProps: { className: "" },
};

export default withErrorBoundary(Slider, { FallbackComponent: ErrorFallback });
