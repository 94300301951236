import Img from "components/atoms/Img";
import { Fragment } from "react";
import Slider from "../Slider";

import styles from "./index.module.scss";

import { ErrorBoundary, withErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/common/ErrorFallback";

const BucketDesktop = ({ data, ...props }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {data.images?.length > 1 ? (
        <>
          <div className="flex items-stretch justify-center lg:items-end lg:space-x-8">
            {data.images.map((site, idx) => {
              return (
                <Img
                  key={`image-${idx}`}
                  src={site.src}
                  width={site.width}
                  height={site.height}
                  style={{
                    marginBottom: `${idx * 80}px`,
                    marginTop: "",
                  }}
                />
              );
            })}
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="inset-0 w-full lg:absolute lg:w-6/12">
        {data.description}
      </div>
      <div className="bottom-0 right-0 w-full rounded-xl bg-arrivalhub-orange-800 p-2 text-center text-xl font-bold text-white shadow-[0px_15px_40px_rgba(0,0,0,0.12)] lg:absolute lg:w-5/12 lg:py-6 lg:px-8">
        {data.button?.content}
      </div>
    </ErrorBoundary>
  );
};

const BucketMobile = ({ data, ...props }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="mb-6 text-base font-normal text-arrivalhub-indigo-800">
        {data.description}
      </div>
      <div className="mb-4 cursor-pointer text-center text-base font-semibold text-arrivalhub-orange-800">
        {data.button?.content}
      </div>
      {data.images?.length > 1 ? (
        <>
          <div className="lg:px-auto row px-[5px]">
            {data.images.map((site, idx) => {
              return (
                <Img
                  key={`image-${idx}`}
                  src={site.src}
                  width={site.width}
                  height={site.height}
                  className="h-full w-full"
                  wrapper={{
                    className: "col-6 mb-5 lg:px-auto px-[10px]",
                  }}
                />
              );
            })}
          </div>
        </>
      ) : (
        <></>
      )}
    </ErrorBoundary>
  );
};

const Buckets = ({ buckets }) => {
  return (
    <Slider
      size="full"
      className="pb-1 lg:pb-6"
      settings={{
        dots: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        effect: "fade",
        autoPlay: true,
        infinite: true,
        speed: 3000,
        // vertical: true,
        // spaceBetween: 20,
        responsive: [
          {
            breakpoint: 990,
            settings: {
              dots: true,
              arrows: false,
            },
          },
        ],
      }}
      dotsProps={{
        wrapperProps: {
          className: styles["buckets-slide-dots"],
        },
        className: styles["slider-dot"],
        activeClassName: styles["slider-dot-active"],
      }}
    >
      {buckets?.map((bucket, idx) => {
        return (
          <Fragment key={`bucket-${idx}`}>
            <div className="relative hidden lg:block">
              <BucketDesktop className="relative h-auto w-full" data={bucket} />
            </div>
            <div className="lg:hidden">
              <BucketMobile data={bucket} />
            </div>
          </Fragment>
        );
      })}
    </Slider>
  );
};

export default withErrorBoundary(Buckets, { FallbackComponent: ErrorFallback });
