import React, { useEffect, useState } from "react";
import styles from "./Index.module.scss";
// import Title from "components/atoms/Title";
import Img from "components/atoms/Img";
import Tooltip from "components/atoms/Tooltip";

// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import Slider from "../Slider";
import LazyLoad from "react-lazyload";

import { withErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/common/ErrorFallback";

const locations = [
  {
    key: "ha-giang",
    id: 0,
    name: "Ha Giang",
    title: "The Gem of Northeast Vietnam",
    placement: "bottom-left",
    imageMobile: {
      src: "/assets/images/locations/ha-giang/ha-giang-mobile.jpg",
      width: 826,
      height: 620,
    },
    description:
      "Take a chance to explore beautiful and majestic sceneries in Ha Giang. It is home to 24 ethnic minority groups, each with their own unique culture and traditions.",

    sites: [
      {
        title: "Dong Van Plateau",
        description:
          "The gorgeous highland with the old town of Mong, Tay and Chinese families behind the dense fog",
        image: {
          src: "/assets/images/locations/ha-giang/ha-giang-1.png",
          width: 190,
          height: 183,
        },
      },
      {
        title: "Meo Vac Town",
        description: "The renowned heaven of peach blossom flowers in Spring",
        image: {
          src: "/assets/images/locations/ha-giang/ha-giang-2.png",
          width: 191,
          height: 168,
        },
      },
      {
        title: "Ma Pi Leng Pass",
        description:
          "The truly splendor and miracle of nature that can make your heart beating wildly",
        image: {
          src: "/assets/images/locations/ha-giang/ha-giang-3.png",
          width: 191,
          height: 183,
        },
      },
    ],
  },
  {
    key: "ha-noi",
    id: 1,
    name: "Hanoi",
    title: "The capital of thousand years of civilization",
    placement: "bottom-left",
    imageMobile: {
      src: "/assets/images/locations/ha-noi/ha-noi-mobile.jpg",
      width: 826,
      height: 548,
    },
    description:
      "Whether you're looking to explore Vietnam's rich history or experience its modern, cosmopolitan side, Hanoi is the perfect destination.",
    sites: [
      {
        title: "Ho Chi Minh Mausoleum",
        description: "The final resting place of the Uncle Ho",
        image: {
          src: "/assets/images/locations/ha-noi/ha-noi-1.png",
          width: 188,
          height: 168,
        },
      },
      {
        title: "One Pillar Pagoda",
        description:
          "One of the finest examples of Vietnamese architecture built in the 11th century",
        image: {
          src: "/assets/images/locations/ha-noi/ha-noi-2.png",
          width: 191,
          height: 168,
        },
      },
      {
        title: "Hanoi Opera House",
        description:
          "The iconic and magnificent building for Hanoi's architectural heritage by the French gov",
        image: {
          src: "/assets/images/locations/ha-noi/ha-noi-3.png",
          width: 191,
          height: 183,
        },
      },
    ],
  },
  {
    key: "hoi-an",
    id: 2,
    name: "Hoi An",
    title: 'The "Venice of the East" Vietnam',
    placement: "top-right",
    imageMobile: {
      src: "/assets/images/locations/hoi-an/hoi-an-mobile.jpg",
      width: 826,
      height: 549,
    },
    description:
      "Hoi An is truly a unique tourist attraction definitely worth being added to your travel list! You simply cannot miss out on the best destinations in Hoi An.",
    sites: [
      {
        title: "Ancient Town",
        description:
          "A well-preserved town full of traditional architecture, temples, and museums - a perfect mix of Japanese, Chinese, French, and Vietnamese architecture",
        image: {
          src: "/assets/images/locations/hoi-an/hoi-an-1.png",
          width: 190,
          height: 231,
        },
      },
      {
        title: "Hoi An Street Market",
        description:
          "Bustling and busy markets for a true taste of Vietnam, local shops, signature silk and paper lamps",
        image: {
          src: "/assets/images/locations/hoi-an/hoi-an-2.png",
          width: 190,
          height: 183,
        },
      },
      {
        title: "My Son Sanctuary",
        description:
          "As an added bonus, this UNESCO site will make you want to return to Hoi An over and over again",
        image: {
          src: "/assets/images/locations/hoi-an/hoi-an-3.png",
          width: 191,
          height: 183,
        },
      },
    ],
  },
  {
    key: "da-nang",
    id: 3,
    name: "Da Nang",
    title: "Vietnam's most worth-living city ",
    placement: "bottom-left",
    imageMobile: {
      src: "/assets/images/locations/da-nang/da-nang-mobile.png",
      width: 450,
      height: 600,
    },
    description:
      "Also known as the City of Bridges, Da Nang is a must-see attraction with beautiful beaches and resorts, fascinating construction sites, and a dynamic and amazing city with growing infrastructure.",
    sites: [
      {
        title: "Dragon Bridge",
        description:
          "The impressive mix of modern structure and unique beauty built with 5 steel pipes and 15,000 LED bulbs",
        image: {
          src: "/assets/images/locations/da-nang/da-nang-1.png",
          width: 190,
          height: 207,
        },
      },
      {
        title: "Bana Hills",
        description:
          "The inspiring landscape to feel 4 seasons in a day, the “Da Lat” of the Central",
        image: {
          src: "/assets/images/locations/da-nang/da-nang-2.png",
          width: 191,
          height: 168,
        },
      },
      {
        title: "My Khe Beach",
        description:
          "One of the most attractive beautiful beaches on the planet by Forbes magazine",
        image: {
          src: "/assets/images/locations/da-nang/da-nang-3.png",
          width: 191,
          height: 183,
        },
      },
    ],
  },
  {
    key: "nha-trang",
    id: 4,
    name: "Nha Trang",
    title: "The Beauty of the Beach City",
    placement: "bottom-left",
    imageMobile: {
      src: "/assets/images/locations/nha-trang/nha-trang-mobile.jpg",
      width: 2500,
      height: 1406,
    },
    description:
      "Nha Trang, renowned as the Beach City in Vietnam, will absolutely wow you with alluring beaches, coral reefs, scuba diving and snorkeling; you also should not miss out to explore the fresh fish and shellfish found in the seafood capital of Vietnam.",
    sites: [
      {
        title: "Vinpearl Land and Ocean Park",
        description:
          "Be sure you will stop by this fantastic amusement park to relax and enjoy your holiday",
        image: {
          src: "/assets/images/locations/nha-trang/nha-trang-1.png",
          width: 190,
          height: 207,
        },
      },
      {
        title: "Po Nagar Cham Towers",
        description: "Featuring the disctinct culture by the Cham people",
        image: {
          src: "/assets/images/locations/nha-trang/nha-trang-2.png",
          width: 191,
          height: 168,
        },
      },
      {
        title: "Nha Trang Beaches",
        description:
          "The white sand, clear water, incredible scenery, and scuba diving sites",
        image: {
          src: "/assets/images/locations/nha-trang/nha-trang-3.png",
          width: 190,
          height: 168,
        },
      },
    ],
  },
  {
    key: "hcm",
    name: "Ho Chi Minh City",
    title: "The Pearl of Far East",
    placement: "bottom-left",
    id: 5,
    imageMobile: {
      src: "/assets/images/locations/hcm/hcm-mobile.jpg",
      width: 660,
      height: 825,
    },
    description:
      "Sai Gon, or Ho Chi Minh city is a bustling, vibrant city full of entertainment and activities, both day and night. Enjoying a dinner cruise tour down the Saigon River is an amazing experience for any travellers. Sai Gon also reflects well-preserved colonial structures to discover the fascinating history of Vietnam around the city.",
    sites: [
      {
        title: "Landmark 81 Vietnam",
        description:
          "The 461-meter (1,514ft) supertall skyscraper in HCMC with 81-story structure and unique design inspired by bamboo images",
        image: {
          src: "/assets/images/locations/hcm/hcm-1.png",
          width: 190,
          height: 225,
        },
      },
      {
        title: "The Notre Dame Cathedral",
        description:
          "A prime example of neo-Romanesque and Gothic architecture by French colonists",
        image: {
          src: "/assets/images/locations/hcm/hcm-2.png",
          width: 190,
          height: 208,
        },
      },
      {
        title: "Ben Thanh Market",
        description:
          "Located in the heart of HCMC and offers a wide variety of local food, souvenirs, and handicrafts",
        image: {
          src: "/assets/images/locations/hcm/hcm-3.png",
          width: 190,
          height: 183,
        },
      },
    ],
  },
  {
    key: "phu-quoc",
    name: "Phu Quoc",
    id: 6,
    title: "The Paradise Pearl Island of Vietnam",
    placement: "bottom-left",
    imageMobile: {
      src: "/assets/images/locations/phu-quoc/phu-quoc-mobile.png",
      width: 450,
      height: 338,
    },
    description:
      "Alongside the southwest coast, you'll explore a sunny, magnificent paradise, among the most-visited natural attractions of Vietnam - Phu Quoc. The island is home to beautiful pearls, fish sauce, and pepper.",
    sites: [
      {
        title: "Phu Quoc's beaches",
        description:
          "Try a breathtaking escape! Golden sand, clear blue water, and spending your days lounging on Dai Beach or Hon Thom.",
        image: {
          src: "/assets/images/locations/phu-quoc/phu-quoc-1.png",
          width: 191,
          height: 207,
        },
      },
      {
        title: "Ham Ninh Fishing Village",
        description:
          "Best known for fresh seafood on floating restaurants, enjoying the unique flavor of fish sauce Phu Quoc",
        image: {
          src: "/assets/images/locations/phu-quoc/phu-quoc-2.png",
          width: 191,
          height: 207,
        },
      },
      {
        title: "Suoi Da Ban Hike",
        description:
          "Adventurers would have an incredible time exploring to trek the trails, go through lush forest and ends at beautiful waterfalls with stunning scenery",
        image: {
          src: "/assets/images/locations/phu-quoc/phu-quoc-3.png",
          width: 190,
          height: 231,
        },
      },
    ],
  },
];

const Card = ({ data }) => {
  return (
    <div className=" relative rounded-lg ">
      <div className=" flex justify-center rounded-lg">
        <div
          className="h-auto w-[190px] rounded-lg"
          style={{ boxShadow: " 0px 8px 20px rgb(0 0 0 / 25%) " }}
        >
          <Img
            src={data?.image?.src}
            width={data?.image?.width}
            height={data?.image?.height}
            className="aspect-square h-full w-full rounded-tl-lg rounded-bl-lg object-cover"
            wrapper={{ className: "w-full h-full" }}
          />
        </div>
        <div
          className="col-7 inline-block rounded-tr-lg rounded-br-lg bg-white p-4  lg:py-4 lg:px-2 xl:p-4"
          style={{ boxShadow: " 0px 8px 20px rgb(0 0 0 / 25%) " }}
        >
          <h4 className="mb-2 font-Montserrat font-bold  text-arrivalhub-gray-800 xl:mb-3 ">
            {data?.title}
          </h4>
          <div className="xl:text-md text-xs text-arrivalhub-indigo-800 lg:text-sm">
            {data?.description}
          </div>
        </div>
      </div>
    </div>
  );
};
const CardMobile = ({ data }) => {
  return (
    <div className="relative mx-auto max-w-[360px] rounded-lg">
      <div className="w-full justify-center">
        <div className="h-[229px] w-full">
          <Img
            src={data?.imageMobile?.src}
            width={data?.imageMobile?.width}
            height={data?.imageMobile?.height}
            className="h-full w-full rounded-lg object-fill"
            wrapper={{ className: "w-full h-full absolute left-0 -z-10 top-0" }}
          />
        </div>
        <div className=" relative z-20 inline-block h-[229px] rounded-bl-lg rounded-br-lg bg-card-opacity p-4 lg:py-4 lg:px-2 xl:p-4">
          <h4 className="mb-2  font-Montserrat  font-bold text-arrivalhub-gray-800 xl:mb-3">
            {data?.name}
          </h4>
          <p className="mb-1">{data.title}</p>
          <div className="xl:text-md pb-13 text-xs text-arrivalhub-indigo-800 lg:text-sm">
            {data?.description}
          </div>
        </div>
      </div>
    </div>
  );
};
const LocationContent = ({ data, ...props }) => {
  return (
    <div className="flex flex-col items-start">
      <div className="font-Montserrat text-xs text-arrivalhub-indigo-800">
        {data?.name}
      </div>
      <div className="flex flex-col items-start">
        <h5 className="mb-2 font-Montserrat text-lg font-semibold text-arrivalhub-raisin-black">
          {data?.title}
        </h5>
        <div
          className="text-sm text-arrivalhub-indigo-800"
          dangerouslySetInnerHTML={{ __html: data?.description }}
        />
      </div>
    </div>
  );
};

const Map = (props) => {
  const [openNode, setOpenNode] = useState({ "ha-noi": true });
  const [change, setChange] = useState(false);
  const [changeMobile, setChangeMobile] = useState({ currentSlide: 1 });

  useEffect(() => {
    setTimeout(() => {
      setChange(true);
    }, 500);
  }, [openNode]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 5000,
    autoPlay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    spaceBetween: 5,
    dragable: true,
    beforeChange: (prev, next) => {
      setChangeMobile({ currentSlide: next });
    },
    afterChange: (current) => {},
  };
  return (
    <div className="select-none bg-arrivalhub-alice-blue-base pt-10 pb-10 lg:pt-15 lg:pb-15">
      <div className="container">
        <h2 className="mb-6 text-center font-Montserrat  text-3xl font-bold leading-10 text-black lg:text-arrivalhub-title">
          Best Places To Visit In{" "}
          <span className="text-arrivalhub-main">Vietnam</span>
        </h2>
        <div className="relative block lg:hidden">
          <LazyLoad>
            <Slider
              settings={settings}
              className="pt-10 pb-10"
              dotsProps={{
                wrapperProps: {
                  className: styles["map-slide-dots"],
                },
                className: styles["slider-dot"],
                activeClassName: styles["slider-dot-active"],
              }}
            >
              {locations?.map((bucket, idx) => {
                return <CardMobile data={bucket} key={idx} />;
              })}
            </Slider>
          </LazyLoad>
        </div>
        <div className="row justify-between">
          <div className="col-6 relative hidden lg:block">
            <Img
              src={`/assets/images/home/map.png`}
              width="full"
              height="auto"
              wrapper={{ className: "bottom-0 w-full z-10" }}
              className=" z-10 w-full object-cover lg:block"
              alt="map"
            />
            {locations.map((location, idx) => {
              return (
                <div
                  key={idx}
                  className={`${styles["location-node"]} hidden lg:block`}
                  data-location={location.key}
                >
                  <Tooltip
                    content={<LocationContent data={location} />}
                    placement={location.placement}
                    contentProps={{
                      className:
                        "bg-white border-2 border-arrivalhub-orange-800 p-6",
                    }}
                    useCloseButton={true}
                    isOpen={
                      openNode[location.key] && openNode[location.key]
                        ? true
                        : false
                    }
                    onStateChange={(show) => {
                      setOpenNode({ [location.key]: show });
                      if (show) {
                        setChange(
                          openNode[location.key] === true ? true : false
                        );
                      }

                      // setOpenNode(show === true ? location.key : "");
                      // setChange(openNode === location.key ? true : false);
                    }}
                    className="h-full w-full"
                  >
                    <div
                      className={`absolute z-10 inline-flex h-full w-full cursor-pointer rounded-full opacity-75 ${
                        openNode[location.key] === true
                          ? "pointer-events-none animate-ping bg-arrivalhub-windsor-tan"
                          : "bg-arrivalhub-main"
                      }`}
                    ></div>
                  </Tooltip>
                </div>
              );
            })}
          </div>

          <div className="flex flex-col justify-center space-y-4 lg:hidden lg:col-5 lg:space-y-10">
            {Object.keys(openNode) &&
              Object.keys(openNode).length !== 0 &&
              locations
                .find((x) => x.id === changeMobile.currentSlide)
                ?.sites?.map((site, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`overflow-shidden transform rounded-lg transition-all duration-700 ease-in-out ${
                        change
                          ? " scale-100 opacity-100"
                          : " scale-0 opacity-20"
                      }`}
                    >
                      <Card data={site} />
                    </div>
                  );
                })}
          </div>
          {/* desktop */}
          <div className=" hidden flex-col justify-center space-y-4 lg:flex  lg:col-5 lg:space-y-10">
            {Object.keys(openNode) &&
              Object.keys(openNode).length !== 0 &&
              locations
                .find((x) => Object.keys(openNode).some((key) => key === x.key))
                ?.sites?.map((site, idx) => {
                  return (
                    <div
                      style={{ boxShadow: "0px 8px 20px rgb(0 0 0 / 25%)" }}
                      key={idx}
                      className={`transform overflow-hidden rounded-lg transition-all duration-500 ease-in-out ${
                        change
                          ? " scale-100 opacity-100"
                          : " scale-0 opacity-20"
                      }`}
                    >
                      <Card data={site} />
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(Map, { FallbackComponent: ErrorFallback });
