import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import styles from "./index.module.scss";

const Slide = ({
  className,
  activeClassName,
  children,
  width,
  isActive,
  passSlideHeight,
  ...wrapperProps
}) => {
  const [slideElement, setSlideElement] = useState(null);

  useEffect(() => {
    if (!slideElement) {
      return;
    }

    const resize = () => {
      passSlideHeight && passSlideHeight(slideElement.offsetHeight);
    };

    resize();
    const onResize = window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [slideElement]);

  return (
    <div
      ref={(el) => {
        if (!el) return;
        setSlideElement(el);
      }}
      className={`${styles["slider-slide"]} ${className || ""} ${
        isActive === true
          ? activeClassName || styles["slider-slide-active"]
          : ""
      }`}
      style={{ width: `${width}px` }}
      {...wrapperProps}
    >
      {children}
    </div>
  );
};

Slide.propTypes = {
  children: PropTypes.any,
  wrapperProps: PropTypes.object,
};

export default Slide;
