import Header from "components/common/Layout/Header";

const Layout = ({ children, seo, ...props }) => {
  return (
    <>
      <Header />
      <main>{children}</main>
    </>
  );
};

export default Layout;
