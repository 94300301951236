/* eslint-disable capitalized-comments */
import axios from "axios";

const getBrowserName = (agent) => {
  switch (true) {
    case agent.indexOf("edge") > -1:
      return "MS Edge";
    case agent.indexOf("edg/") > -1:
      return "Edge ( chromium based)";
    case agent.indexOf("opr") > -1 && Boolean(window.opr):
      return "Opera";
    case agent.indexOf("chrome") > -1 && Boolean(window.chrome):
      return "Chrome";
    case agent.indexOf("trident") > -1:
      return "MS IE";
    case agent.indexOf("firefox") > -1:
      return "Mozilla Firefox";
    case agent.indexOf("safari") > -1:
      return "Safari";
    default:
      return "other";
  }
};

const setDataLocal = (valueToStore) => {
  window.localStorage.setItem("visit_site", JSON.stringify(valueToStore));
};

const sendErrorDataToServer = (error) => {
  try {
    // eslint-disable-next-line func-names, space-before-function-paren
    const browserName = (function (agent) {
      return getBrowserName(agent);
    })(window.navigator.userAgent.toLowerCase());

    const errorInfor = {
      url: window.location.href,
      browser_name: browserName,
      time: new Date().toString(),
      website_id: process.env.NEXT_PUBLIC_WEBSITE_ID,
      client_id: "0",
      description: error.toString(),
    };

    axios
      .post(
        `${process.env.NEXT_PUBLIC_API_FORM}/api/onboarding/tracking/email_white_page`,
        errorInfor
      )
      .then((res) => res)
      .then((data) => data)
      .catch((error) => console.log("send error:", error));
  } catch (error) {
    console.log("error before send data to server ", error);
  }
};

const handleError = (error) => {
  try {
    if (window.location.hostname !== "localhost") {
      const saveInfo = {
        uri: window.location.pathname,
        time: new Date().getTime(),
      };

      const dataLocal = JSON.parse(window.localStorage.getItem("visit_site"));

      if (dataLocal) {
        const filterSite = dataLocal.find(
          (item) => item.uri === window.location.pathname
        );

        const timeDelay = 1 * 60 * 60 * 1000; // 1h

        if (filterSite) {
          if (saveInfo.time - filterSite.time > timeDelay) {
            const tempData = dataLocal.map((item) => {
              // cap nhat du lieu loi moi
              if (item.uri === saveInfo.uri) {
                return saveInfo;
              }

              return item;
            });

            setDataLocal(tempData);
            sendErrorDataToServer(error);
          }
        } else {
          setDataLocal([...dataLocal, saveInfo]);
          sendErrorDataToServer(error);
        }
      } else {
        setDataLocal([saveInfo]);
        sendErrorDataToServer(error);
      }
      console.log(error);
    }
  } catch (error) {
    console.log("init event:", error);
  }
};

export default handleError;
