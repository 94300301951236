import { useEffect, useState } from "react";
const animateCountUp = (el) => {
  const animationDuration = 4000;

  const frameDuration = 1000 / 60;

  const totalFrames = Math.round(animationDuration / frameDuration);

  const easeOutQuad = (t) => t * (2 - t);
  let frame = 0;
  const countTo = parseInt(el.innerHTML, 10);

  const counter = setInterval(() => {
    frame++;

    const progress = easeOutQuad(frame / totalFrames);

    const currentCount = Math.round(countTo * progress);

    if (parseInt(el.innerHTML, 10) !== currentCount) {
      el.innerHTML = currentCount;
    }

    if (frame === totalFrames) {
      clearInterval(counter);
    }
  }, frameDuration);
};
export default function CountUp({ data }) {
  const [isCount, setIsCount] = useState(false);

  useEffect(() => {
    const detect = () => {
      const sectionAboutUs = document.querySelector("#count-up");
      if (sectionAboutUs) {
        const sectionAboutUsOffsetTop = sectionAboutUs.offsetTop + 60;
        if (
          window.pageYOffset >
          sectionAboutUsOffsetTop - sectionAboutUsOffsetTop / 2
        ) {
          setIsCount(true);
        }
      }
    };
    window.addEventListener("scroll", () => {
      detect();
    });
    window.addEventListener("resize", () => {
      detect();
    });
  }, []);
  useEffect(() => {
    if (isCount) {
      const counters = document.querySelectorAll(".count");
      counters.forEach(animateCountUp);
    }
  }, [isCount]);

  return (
    <div className="col-10 mx-auto">
      <div className="row justify-between" id="count-up">
        {data?.map((item, index) => {
          return (
            <div
              key={item.id}
              className={`text-center last:mb-0 lg:col-4 ${
                index < 4 ? "mb-8 lg:mb-0" : "mb-8 lg:mb-0"
              }`}
            >
              <div
                className="mb-2 font-Montserrat font-bold text-arrivalhub-main "
                style={{ fontSize: `64px`, lineHeight: `60px` }}
              >
                <span className="count ">{item.title}</span>

                {index == 2 ? <span>%</span> : <span>+</span>}
              </div>
              <div
                className="text-2xl text-black"
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
